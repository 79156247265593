.tinymce-editor {
  min-height: 400px;
  border-radius: 10px;

  .bf-container {
    background-color: #fff;
  }

//   
}

.pro-desc .ant-btn {
    margin-top: 10px;
    margin-right: 10px;
}

.sku-info-block {
  background-color: #fff;
  padding: 12px 24px 24px;
  border-radius: 12px;
  margin-bottom: 24px;
  position: relative;
}

.sku-tag-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;

  .sku-key {
      width: 120px;
  }

  .sku-val {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-left: 24px;
  }

  .inp {
      width: 120px;
      margin-right: 12px;
      margin-bottom: 12px;

      &:hover {
          .ant-input-suffix {
              display: block;
          }
      }

      .ant-input-suffix {
          display: none;
          position: relative;
          width: 20px;
          height: 20px;

          .iconfont {
              position: absolute;
              height: 20px;
              width: 20px;
              z-index: 10;
              color: #f00;
              font-size: 20px;
              line-height: 20px;
              cursor: pointer;
          }
      }
  }

  .icon-jiahao {
      font-size: 20px;
      color: green;
      cursor: pointer;
  }
}