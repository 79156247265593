.notice-list-container {

  .top-panel-wrap {
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
    border-bottom: 1px dashed #ccc;

    .item-panel,
    .create-btn {
      min-width: 300px;
      max-width: 300px;
      width: 300px;
      height: 150px;
      border-radius: 6px;
      border: 1px solid #ccc;
      text-align: center;
      line-height: 150px;
      font-size: 40px;
      color: #ccc;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      margin: 0 8px 24px;

      .item-img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }

      &:hover {
        .footer {
          bottom: 0px;
        }
      }

      .footer {
        transition: all .3s;
        position: absolute;
        left: 0;
        bottom: -60px;
        right: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: rgba(0, 0, 0, .65);

        .opt {
          flex: 1;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          color: #fff;
          transition: all .3s;

          &:hover {
            width: 80px;
            height: 100%;
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .create-btn:hover {
    font-size: 56px;
    transition: all .3s;
    border-style: solid;
  }

  .bottom-panel {
    width: 600px;
    height: 300px;
    margin: 40px auto 0;
  }
}