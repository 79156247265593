.export-modal {
  width: 420px !important;

  .ant-radio-group {
    display: flex;
    flex-direction: row;

    .ant-radio-button-wrapper {
      flex: 1;
      text-align: center;
    }
  }
}

.date-picker {
  margin: 20px 0;
}