.flex {
  display: flex;
}
.text-center {
  text-align: center;
}

.pic-img {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 8px;
  position: relative;
  .ant-image-img {
    border-radius: 8px;
  }

  .ant-image::before {
    position: absolute;
    z-index: 1;
    width: calc(100%);
    height: calc(100%);
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 0;
    transition: all 0.3s;
    content: " ";
  }

  .ant-image:hover::before {
    opacity: 1;
  }

  .remove-icon::before {
    position: absolute;
    z-index: 1;
    width: calc(100%);
    height: calc(100%);
    background-color: rgba(0, 0, 0, 0.45);
    opacity: 0;
    transition: all 0.3s;
    content: " ";
  }

  .remove-icon:hover::before {
    opacity: 1;
  }

  .remove-icon {
    position: absolute;
    right: 45px;
    top: 45px;
    z-index: 9;
    color: #fff;
    opacity: 0;
  }
}