.console-layout-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 26px;
    box-sizing: border-box;

    .header-left-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;

        .logo {
            // font-size: 36px;
            // color: var(--theme-color);
            // margin-right: 12px;
            // animation: rotate .1s linear infinite;
            width: 36px;
            height: 36px;
            border-radius: 10px;
            margin-right: 12px;
        }

        @keyframes rotate {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }

        .logo-title {
            font-size: 24px;
            color: var(--theme-color);
            font-weight: bold;
        }

    }

    .header-middle-wrap {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding: 0 24px;
    }

    .header-right-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;

        .user-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            .iconfont {
                background-color: var(--theme-color);
                width: 28px;
                height: 28px;
                line-height: 28px;
                border-radius: 50%;
                text-align: center;
                color: #fff;
                font-size: 16px;
                margin-right: 8px;
            }

            .user-name {
                white-space: nowrap;
                word-wrap: none;
                max-width: 120px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #fff;
            }
        }
    }
}

.tips-content {
    padding: 0;
    width: 80px;
    p {
        text-align: center;
        cursor: pointer;
    }
}