.add-product-container {
    position: relative;

    .submit-btn {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 10;
    }

    .base-info {
        font-size: 24px;
        // margin-top: 24px;
    }

    .sku-content {
        margin-bottom: 20px;

        .sku-item {
            background-color: #fff;
            min-height: 200px;
            padding: 20px;
            margin-bottom: 20px;
            position: relative;

            .value {
                margin: 20px 0;

                .ant-input {
                    margin-right: 10px;
                    margin-bottom: 10px;
                }
            }

            .ant-input {
                width: 200px;
            }

            .showDelete {
                position: absolute;
                right: 20px;
                top: 20px;
            }

            .hiddenDelete {
                display: none;
            }
        }
    }

}